module.exports = [{
      plugin: require('../node_modules/gatsby-head-style-boss/gatsby-browser.js'),
      options: {"plugins":[],"minifyBrowserFunction":false,"iifeDebugLevel":5,"generalDebugLevel":5,"styleConfigs":[{"key":"normalize","displayName":"Normalize2 Reset","alwaysEnabled":true,"componentType":"STYLE","crossorigin":"false","pathToCSSFile":"./src/styles/normalize2.css"},{"key":"core","displayName":"Core Theme","alwaysEnabled":true,"componentType":"STYLE","crossorigin":"false","pathToCSSFile":"./src/styles/coreTheme.css","minify":true},{"key":"light","displayName":"Default, Light Theme","alwaysEnabled":false,"uses":"default","componentType":"STYLE","pathToCSSFile":"./src/styles/lightTheme.css","minify":false},{"key":"dark","displayName":"Dark Theme","alwaysEnabled":false,"uses":"dark","componentType":"STYLE","pathToCSSFile":"./src/styles/darkTheme.css","minify":false},{"key":"burger","displayName":"Burger King","componentType":"LINK","remoteHREF":"https://www.edpike365.com/gatsby-head-style-boss-test/burger.css","cacheRemoteCSS":false},{"key":"fire","displayName":"Fire Theme","alwaysEnabled":false,"componentType":"STYLE","pathToCSSFile":"./src/styles/fireTheme.css","media":"(max-width: 1280px)","minify":false}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":740,"showCaptions":["title"],"linkImagesToOriginal":true,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"elements":["h1","h2","h3"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludeRegex":{},"excludePattern":"excluded-link"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EdPike365 Blog and Portfolio","short_name":"EdPike365","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/logo-icon.svg","icons":[{"src":"icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"crossOrigin":"use-credentials","theme_color_in_head":true,"legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"bd8df49b825db4711caa366f0ffbd177"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
